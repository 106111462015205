export const sizes = {
	orient(dir) {
		const direction = {
			portrait  : 'portrait',
			landscape : 'landscape'
		};
		return `@media only screen and (orientation: ${direction[dir]})`;
	},
	up(size) {
		const sizes = {
			xs       : '575.98px',
			sm       : '767.98px',
			md       : '943.98px',
			lg       : '1199.98px',
			xl       : '1440px',
			mobBreak : '1000px'
		};
		return `@media (min-width: ${sizes[size]})`;
	},
	down(size) {
		const sizes = {
			xxs      : '399.98px',
			xs       : '575.98px',
			sm       : '767.98px',
			md       : '943.98px',
			lg       : '1199.98px',
			xl       : '1440px',
			mobBreak : '1000px'
		};
		return `@media (max-width: ${sizes[size]})`;
	},
	get(size) {
		const sizes = {
			xxs      : '399.98px',
			xs       : '575.98px',
			sm       : '767.98px',
			md       : '943.98px',
			lg       : '1199.98px',
			xl       : '1440px',
			mobBreak : '1000px'
		};
		return sizes[size];
	}
};

export const getInnerHeight = (num) => {
	if (typeof window !== 'undefined') {
		return window.innerHeight * num;
	}
};

export const getInnerWidth = (num) => {
	if (typeof window !== 'undefined') {
		return window.innerWidth * num;
	}
};
