import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components";
import Navbar from "./navbar";
import Footer from "./footer";

import "./layout.css"
import Img from "../images/ReclaimerLogo.png";


const PageWrapper = styled.main`
  flex-grow: 1;
  flex-shrink: 0;
  margin: 99px auto 0 auto;
  width: 100%;
`;

const Layout = ({ children, location}) => {
  console.log(location);
  return (
    <>
        <Navbar location={location} background={"var(--Primary-color)"} logo={Img} pages={["HOME", "GAMES", "TEAM", "CONTACT"]}/>
        <PageWrapper>{children}</PageWrapper>
        <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
