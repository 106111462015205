import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { sizes, getInnerWidth, getInnerHeight } from './utils';
import MenuIcon from '../images/MenuButton.svg';
import { gsap, Expo } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import newTabIcon from '../images/newTab.svg';

gsap.registerPlugin(ScrollToPlugin);
const plugins = [ gsap, ScrollToPlugin ];
const NavBarWrapper = styled.header`
	position: fixed;
	z-index: 10;
	background: ${(props) => (props.background ? props.background : 'red')};
	width: 100%;
	height: 100px;
	margin: 0;
	display: flex;
	justify-content: space-around;
	div {
		display: flex;
		justify-content: center;
		width: fit-content;
		align-items: center;
		> h2 {
			margin: 0;
			font-size: 2rem;
			letter-spacing: 4px;
			font-family: "dinAlternate";
			font-weight: 0;
			color: white;
			${[ sizes.down('sm') ]} {
				font-size: 1.4rem;
			}
			> span {
				font-weight: 800;
				color: #E7197F;
			}
		}
	}
	ul {
		z-index: 10;
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;
		margin: 0;
		padding-bottom: 10px;
		list-style-type: none;
		align-self: flex-end;
		${[ sizes.down('mobBreak') ]} {
			position: fixed;
			flex-direction: column;
			background-color: var(--Primary-color-dark);
			width: 100%;
			height: 90vh;
			align-items: center;
			justify-content: space-between;
			left: -150%;
			transition: left 0.2s linear;
			top: 100px;
			padding: 15% 0;
			margin-left: 0;
		}
		li {
			z-index: 10;
			margin: 0;
			margin-right: 20px;
			color: ${(props) => (props.color ? props.color : 'red')};
			cursor: pointer;
			font-size: 1.2rem;
			a {
				color: white;
			}
			&:last-of-type {
				${[ sizes.down('mobBreak') ]} {
					a {
						margin-left: 23px;
					}
				}
			}
			${[ sizes.down('mobBreak') ]} {
				font-size: 1.5rem;
				text-align: center;
			}
		}
	}
	img {
		display: block;
		width: auto;
		height: 70%;
		object-fit: contain;
		margin: 0 auto;
		${[ sizes.down('sm') ]} {
			height: 55%;
		}
	}
`;

const MenuWrapper = styled.div`
width                      : ${getInnerWidth(0.05)},
position                   : 'absolute !important',
top                        : '3%',
left                       : '5%',
display                    : 'none',
[sizes.orient('portrait')]: {
	display : 'block'
}
`;

const Navbar = ({ logo, title, background, color, pages, location }) => {
	const innerWidth = getInnerWidth(1);
	const mobBreakPoint = 1000;
	const [ mobDisplay, setMobDisplay ] = useState(innerWidth < 1000);
	const [ menuDisplay, setMenuDisplay ] = useState(false);

	useEffect(() => {
		window.addEventListener('resize', () => {
			if (getInnerWidth(1) < mobBreakPoint) {
				setMobDisplay(true);
			} else {
				setMobDisplay(false);
			}
		});
	}, []);

	const handleNavClick = (e, id) => {
		e.preventDefault();
		setMenuDisplay(false);
		console.log(location.pathname);
		if (location.pathname === '/') {
			gsap.to(window, {
				duration : 1.5,
				scrollTo : {
					y       : id,
					offsetY : 120
				},
				ease     : Expo.easeInOut
			});
		} else {
			navigate(`/`);
			setTimeout(() => {
				gsap.to(window, {
					duration : 1.5,
					scrollTo : {
						y       : id,
						offsetY : 120
					},
					ease     : Expo.easeInOut
				});
			}, 800);
		}
	};

	return (
		<NavBarWrapper background={background} color={color} glowIntensity={1.5}>
			<div>
				<img src={logo} alt="logo" />
				<h2>
					RECLAIMER <span>GAMES</span>
				</h2>
			</div>
			<MenuWrapper>
				{mobDisplay && (
					<img
						src={MenuIcon}
						alt="Menu Icon"
						onClick={() => setMenuDisplay(!menuDisplay)}
					/>
				)}{' '}
				<ul style={menuDisplay ? { left: '0' } : {}}>
					{pages.map((e) => (
						<li key={e.toString().toLowerCase()}>
							<a
								onClick={(evt) =>
									handleNavClick(
										evt,
										e.toLowerCase() === 'home'
											? '#landing'
											: `#${e.toString().toLowerCase()}`
									)}
								href={
									e.toLowerCase() === 'home' ? (
										'/#'
									) : (
										`/#${e.toString().toLowerCase()}`
									)
								}>
								{e}
							</a>
						</li>
					))}
					<li>
						<a
							style={{ display: 'flex' }}
							href="https://reclaimergames.com/press/"
							target="_blank">
							PRESSKIT{' '}
							<img
								style={{
									display    : 'inline',
									height     : '18px',
									marginLeft : '5px',
									width      : 'auto'
								}}
								src={newTabIcon}
								alt="new tab icon"
							/>
						</a>
					</li>
				</ul>
			</MenuWrapper>
		</NavBarWrapper>
	);
};

Navbar.defaultProps = {
	logo       : 'https://unsplash.it/300',
	background : 'indigo',
	color      : 'white',
	pages      : [ 'Home', 'About', 'Contact' ]
};

export default Navbar;
