import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--Primary-color);
	min-height: 50px;
	p {
		color: #c4c4c4;
		padding-right: 5px;
		margin: 0;
		align-self: center;
	}
	a {
		color: white;
	}
`;

const Footer = () => {
	return (
		<FooterWrapper>
			<p>Website designed by</p>
			<a href="https://www.discoverywebdesign.co.uk">DiscoveryWebDesign</a>
		</FooterWrapper>
	);
};

export default Footer;
